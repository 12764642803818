import React, { useEffect, useState } from 'react';
import { AppIcons, AppImages } from '../../assets/images';
import LandingPage from './LandingPage';
import { Filter, Loading, ProfileModal } from '../../components';
import { IFilterHome, IProfile } from '../../core/models';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { emptyProfile } from '../../core/data';
import ProfileCarousel from './ProfileCarousel';
import { Gender } from '../../core/enum';
import { useNavigate } from 'react-router-dom';
import TestimonialCarousel from './TestimonialCarousel';
import { ProfileService } from '../../services';
import { objectToQueryString } from '../../services/utils';

const HomePage = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState({
    show: false,
    data: emptyProfile,
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const onFilter = (e: IFilterHome) => {
    const query = objectToQueryString(e);
    if (e.gender === Gender.FEMALE) {
      navigate(`/bride?${query}`);
    } else if (e.gender === Gender.MALE) {
      navigate(`/groom?${query}`);
    } else {
      alert('Please select Bride or Groom');
    }
  };
  const onOpenFile = (e: string) => {
    navigate('/files/' + encodeURIComponent(e));
  };
  const onOpenProfile = (e: number) => {
    navigate('/profiles/' + e);
  };
  return (
    <div>
      <img src={AppImages.landingImage1} style={{ width: '100%' }} />
      <Filter onFilter={(e) => onFilter(e)} onOpenFile={(e) => onOpenFile(e)} />
      <section className="py-5">
        <ProfileCarousel onOpenProfile={(e) => onOpenProfile(e)} />
      </section>
      <section className="my-2">
        <LandingPage />
      </section>
      <Container>
        <h2
          style={{
            fontFamily: 'Righteous',
            textDecoration: 'underline',
            textAlign: 'center',
            marginTop: '5rem',
          }}
        >
          {' '}
          Why choose Lingayath Weds?
        </h2>
        <Row>
          <Col md={6} className="my-4">
            <div className="d-flex ">
              <img src={AppIcons.form} style={{ height: '64px', marginRight: '10px' }} />
              <div>
                <h5>Get Complete Family Information</h5>
                <p>
                  You will find detailed family information in every profile. Knowing the family
                  will help you take the next step with confidence.
                </p>
              </div>
            </div>
          </Col>
          <Col md={6} className="my-4">
            <div className="d-flex">
              <img src={AppIcons.community} style={{ width: '64px', marginRight: '10px' }} />
              <div>
                <h5>Get Matches from your Community</h5>
                <p>
                  With over 80 community sites, you can find a Match from your community. Finding a
                  Match based on caste and religion made easy.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <section className="my-5 py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="heading-alt text-center">What Customer Says</h2>
              <h5 className="sub-heading text-center">
                Here are some of the satisfactory statements from our esteemed customers about their
                success stories{' '}
              </h5>
              <TestimonialCarousel />
            </Col>
          </Row>
        </Container>
      </section>

      {!loading && (
        <ProfileModal
          profile={modal.data}
          show={modal.show}
          onClose={() => setModal({ data: emptyProfile, show: false, error: false })}
        />
      )}
      <Modal
        show={modal.error}
        onHide={() => {
          setModal({ ...modal, error: false });
        }}
        centered={true}
      >
        <Modal.Body>
          <div className="text-center">Profile Not Found</div>
        </Modal.Body>
        <div className="text-center my-3">
          <Button
            variant="primary"
            onClick={() => {
              setModal({ ...modal, error: false });
            }}
          >
            OK
          </Button>
        </div>
      </Modal>
      <Loading show={loading} />
    </div>
  );
};

export default HomePage;
