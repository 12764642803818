import React, { useEffect, useState } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import { ProfileService } from '../../services';
import { Spinner } from 'react-bootstrap';
import { AppPagination } from '../../components';

const Gallery = () => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<{ id: Number; imageUrl: string }[]>([]);
  const [pagination, setPagination] = useState({
    cur: 1,
    max: 0,
    limit: 20,
  });

  const getImages = async (page: number = 1) => {
    setLoading(true);
    try {
      const res = await ProfileService.getGallery(page, 50);
      if (res.status === 200) {
        if (res.data.data) {
          setImages(res.data.data);
          setPagination({
            cur: res.data.pagination_data.page,
            max: res.data.pagination_data.pages,
            limit: res.data.pagination_data.limit,
          });
        } else {
          setImages([]);
          setPagination({
            cur: 1,
            max: 1,
            limit: 20,
          });
          alert(res.data.message);
        }
      } else {
        alert(res.data.error.message);
      }
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getImages();
  }, []);
  return (
    <>
      <h1 className="heading bg-light mb-0 text-center py-4">Gallery</h1>
      <div className="container my-5">
        <div>
          <LightGallery speed={500} elementClassNames="row" download={false}>
            {!loading &&
              images.map((i) => (
                <a className="gallery-item col-lg-3 m-2" data-src={i.imageUrl}>
                  <img className="img-responsive" src={i.imageUrl} alt="lingayathweds.com" />
                </a>
              ))}
          </LightGallery>
        </div>
        {images.length === 0 && !loading && (
          <h5 className="text-secondary my-5 py-5 text-center">No Images found</h5>
        )}
        {loading && (
          <div className="my-5 py-4 d-flex align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" />{' '}
            <span className="fs-5 ms-1">Loading...</span>
          </div>
        )}
        {images.length > 0 && pagination.max > 1 && (
          <div className="d-none d-md-block">
            <AppPagination
              cur={pagination.cur}
              max={pagination.max}
              limit={pagination.limit}
              onPageChange={(n) => getImages(n)}
            />
          </div>
        )}
        <div className="my-5 py-5"></div>
      </div>
    </>
  );
};

export default Gallery;
