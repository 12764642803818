import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
const firebaseConfig = {
  apiKey: 'AIzaSyDdclzMcbopkSSZzEe6_rwiukLPhRf39ss',
  authDomain: 'lingayath-weds-website.firebaseapp.com',
  projectId: 'lingayath-weds-website',
  storageBucket: 'lingayath-weds-website.appspot.com',
  messagingSenderId: '493960866960',
  appId: '1:493960866960:web:a025eb3e7e885497bb7c15',
  measurementId: 'G-PW9QKVMTM7',
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app, analytics };
