import { IAddProfile } from '../pages/Registration/types';
import { apiClient } from './networkManager';
import { objectToQueryString } from './utils';
export class ProfileService {
  static getBranches = (page: number = 1, limit: number = 10) => {
    return apiClient.get(`/api/branches?page=${page}&limit=${limit}`);
  };
  static addProfile = (profile: IAddProfile) => {
    return apiClient.post(`/api/profiles/register`, profile);
  };
  static getProfiles = (limit: number = 10, page: number = 1) => {
    return apiClient.get(`/api/profiles?limit=${limit}&page=${page}`);
  };
  static getProfile = (id: number) => {
    return apiClient.get(`/api/profiles/findByProfileId/${id}`);
  };
  static getProfileFno = (fno: string) => {
    return apiClient.get(`/api/profiles/findByFileNo?fileNo=${fno}`);
  };
  static getLatestProfiles = () => {
    return apiClient.get(`/api/profiles/latestProfiles`);
  };
  static getDistrict = (page: number = 1, limit: number = 10) => {
    return apiClient.get(`/api/districts?page=${page}&limit=${limit}`);
  };
  static getGallery = (page: number = 1, limit: number = 10) => {
    return apiClient.get(`/api/gallery/getActiveGallery?page=${page}&limit=${limit}`);
  };
  static getTestimonials = (page: number = 1, limit: number = 10) => {
    return apiClient.get(`/api/testimonials/getActiveTestimonials?page=${page}&limit=${limit}`);
  };
  static addDistrict = (district: string) => {
    return apiClient.post(`/api/districts`, { district });
  };
  static getFilteredProfiles = (
    gender: string,
    fromAge: number = null,
    toAge: number = null,
    caste: string = null,
    education: string = null,
    jobtitle: string = null,
    limit: number = 10,
    page: number = 1
  ) => {
    const p = {
      gender: gender,
      fromAge: fromAge,
      toAge: toAge,
      caste: caste,
      education: education,
      jobType: jobtitle,
      limit: limit,
      page: page,
    };
    return apiClient.get(`/api/profiles/filteredProfiles?${objectToQueryString(p)}`);
  };
}
