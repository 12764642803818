import React from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  return (
    <Navbar expand="lg" className="bg-body-tertiary py-md-3 py-1 px-lg-5 px-3">
      <Navbar.Brand>
        <Link className="logo" to={'/'}>
          Lingayath Weds
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link onClick={() => navigate('/home')}>Home</Nav.Link>
          <NavDropdown title="Profile" id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => navigate('/bride')}>Brides</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate('/groom')}>Grooms</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link onClick={() => navigate('/about')}>About Us</Nav.Link>
          <Nav.Link onClick={() => navigate('/contact')}>Contact Us</Nav.Link>
          <Nav.Link onClick={() => navigate('/gallery')}>Gallery</Nav.Link>
          {/* <Nav.Link onClick={() => navigate('/registration')}>Registration</Nav.Link> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default Header;
