import React, { useEffect, useState } from 'react';
import { Col, Container, FloatingLabel, Row, Modal, Form, Button } from 'react-bootstrap';
import { IFilterHome } from '../core/models';
import { FilterType, Gender } from '../core/enum';
import { Castes, EducationStream, JobTypes } from '../core/dropdowns';
import { useSearchParams } from 'react-router-dom';
interface FilterProps {
  onFilter: (e: IFilterHome) => void;
  onOpenFile?: (e: string) => void;
  type?: FilterType;
}
const Filter = (props: FilterProps) => {
  const [filter, setFilter] = useState<IFilterHome>({
    gender: undefined,
    ageFrom: undefined,
    ageTo: undefined,
    caste: '',
    qualification: '',
    jobtitle: '',
  });
  const [params, setParams] = useSearchParams();
  const [fileNumber, setFileNumber] = useState<string>('');
  const fromAge = () => {
    let list = [];
    for (let index = 18; index < 51; index++) {
      list.push(index);
    }
    return list;
  };

  const onFilter = () => {
    props.onFilter(filter);
  };
  const onOpenFile = () => {
    props?.onOpenFile(fileNumber);
  };
  useEffect(() => {
    const ageTo = Number(params.get('ageTo'));
    const ageFrom = Number(params.get('ageFrom'));
    const caste = params.get('caste');
    const qualification = params.get('qualification');
    const jobtitle = params.get('jobtitle');
    setFilter({
      gender: undefined,
      ageFrom: ageFrom,
      ageTo: ageTo,
      caste: caste,
      qualification: qualification,
      jobtitle: jobtitle,
    });
  }, [props, window.location]);

  return (
    <section className="bg-light py-4 px-2">
      <Container fluid>
        <Row className="align-items-center">
          {(!props.type || props.type === FilterType.HOME) && (
            <Col md={3} className="my-1">
              <div className="looking-for justify-content-center">
                <p className="fs-4 me-2 mb-0">Looking For : </p>
                <Form.Check
                  type="radio"
                  label="Bride"
                  name="gender"
                  className="fs-6 me-2"
                  onChange={() => setFilter({ ...filter, gender: Gender.FEMALE })}
                />
                <Form.Check
                  type="radio"
                  label="Groom"
                  name="gender"
                  className="fs-6"
                  onChange={() => setFilter({ ...filter, gender: Gender.MALE })}
                />
              </div>
            </Col>
          )}
          {props.type && !(props.type === FilterType.HOME) && (
            <Col md={3} className="my-1">
              <div className="looking-for justify-content-center">
                <p className="fs-4 me-2 mb-0">Looking For : </p>
              </div>
            </Col>
          )}
          <Col md={2} className="my-1">
            <div className="d-flex className='w-100'">
              <FloatingLabel label="From Age" className="w-50 me-2">
                <Form.Select
                  value={filter.ageFrom}
                  onChange={(e) => setFilter({ ...filter, ageFrom: Number(e.target.value) })}
                >
                  <option value="">Select</option>
                  {fromAge().map((i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel label="To Age" className="w-50 ms-2">
                <Form.Select
                  value={filter.ageTo}
                  onChange={(e) => setFilter({ ...filter, ageTo: Number(e.target.value) })}
                >
                  <option value="">Select</option>
                  {fromAge().map((i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </div>
          </Col>
          <Col md={2} className="my-1">
            <FloatingLabel label="Caste">
              <Form.Select
                value={filter.caste}
                onChange={(e) => setFilter({ ...filter, caste: e.target.value })}
              >
                <option value="">Select Caste</option>
                {Castes.map((i) => (
                  <option value={i.caste} key={i.id}>
                    {i.caste}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md={2} className="my-1">
            <FloatingLabel label="Job Title">
              <Form.Select
                value={filter.jobtitle}
                onChange={(e) => setFilter({ ...filter, jobtitle: e.target.value })}
              >
                <option value="">Select Job Title</option>
                {JobTypes.map((i) => (
                  <option value={i} key={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md={2} className="my-1">
            <FloatingLabel label="Education">
              <Form.Select
                value={filter.qualification}
                onChange={(e) => setFilter({ ...filter, qualification: e.target.value })}
              >
                <option value="">Select Education</option>
                {EducationStream.map((i) => (
                  <option value={i.education_stream} key={i.id}>
                    {i.education_stream}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md={1} className="my-1 text-center d-grid d-md-block">
            <Button onClick={onFilter} variant="secondary">
              Search
            </Button>
          </Col>
        </Row>
        {(!props.type || props.type === FilterType.HOME) && (
          <Row className="justify-content-center align-items-center mt-3">
            <Col md={3} className="my-1">
              <FloatingLabel label="Register No. ">
                <Form.Control
                  placeholder="Enter File Number"
                  value={fileNumber}
                  onChange={(e) => setFileNumber(e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col md={2} className="my-1 text-center d-grid d-md-block">
              <Button onClick={onOpenFile} variant="secondary">
                View Profile
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default Filter;
