import { Button, Image, Carousel } from 'react-bootstrap';
import { AppImages } from '../../assets/images';

const LandingPage = () => {
  const images = [
    AppImages.landingImage2,
    AppImages.landingImage3,
    AppImages.landingImage4,
    AppImages.landingImage5,
    AppImages.landingImage6,
  ];
  return (
    <Carousel>
      {images.map((i, index) => (
        <Carousel.Item>
          <Image style={{ width: '100%', height: '100%' }} src={i} />
          {index == 1 && (
            <Carousel.Caption>
              <p>
                <Button
                  onClick={() => {
                    window.open('http://www.sgstriveniyatra.com');
                  }}
                >
                  Visit Website
                </Button>
              </p>
            </Carousel.Caption>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default LandingPage;
