import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

const DEFAULT_TIMEOUT = 60000 * 3;

export const apiClient = setupCache(
  axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: DEFAULT_TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
    },
  })
);
