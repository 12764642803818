import { IAddProfile } from '../pages/Registration/types';

export const formatDateFromDays = (days: number) => {
  const epoch = new Date(1900, 0, 1);
  const date = new Date(epoch.getTime() + days * 24 * 60 * 60 * 1000);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};
export const calculateAgeFromDays = (days: number) => {
  const milliseconds = days * 24 * 60 * 60 * 1000;
  const birthDate = new Date(Date.now() - milliseconds);
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const birthMonth = birthDate.getMonth();
  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};
export const calculateAgeUnix = (dateOfBirth: number) => {
  const dob = new Date(dateOfBirth); // Assuming dateOfBirth is in a format that Date object can parse
  const now = new Date();
  let age = now.getFullYear() - dob.getFullYear();
  const monthDiff = now.getMonth() - dob.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
    age--;
  }
  return age;
};

export const loadScript = (src: string, callback: () => void) => {
  const script = document.createElement('script');
  script.src = src;
  script.onload = callback;
  document.body.appendChild(script);
};
export const objectToQueryString = (params: any) => {
  const queryString = new URLSearchParams();
  for (const key in params) {
    if (params.hasOwnProperty(key) && params[key]) {
      queryString.append(key, params[key]);
    }
  }

  return queryString.toString();
};
export const calculateAge = (dateString: string) => {
  const today = new Date();
  const [day, month, year] = dateString.split('-').map(Number);
  const birthDate = new Date(year, month - 1, day);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
export const Capitalize = (str: string) => {
  if (typeof str !== 'string' || str.length === 0) {
    return '';
  }
  let words = str.split(' ');
  let capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(' ');
};
