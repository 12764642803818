import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { IProfile } from '../core/models';
import { emptyProfile } from '../core/data';
import { calculateAge } from '../services/utils';
interface ProfileModalProps {
  profile: IProfile;
  show: boolean;
  onClose: () => void;
}

const ProfileModal = (props: ProfileModalProps) => {
  const [modal, setModal] = useState({
    show: false,
    data: emptyProfile,
  });
  const [openImage, setImage] = useState('');

  useEffect(() => {
    setModal({ data: props.profile, show: props.show });
    if (props.profile.profileImages.length > 0) setImage(props.profile.profileImages[0].imageUrl);
  }, [props]);
  const onClose = () => {
    setModal({ data: emptyProfile, show: false });
    setImage('');
    props.onClose();
  };
  return (
    <Modal show={modal.show} fullscreen={true} onHide={onClose} className="profile-modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col md={5}>
              <h3>{props.profile.fileNo}</h3>
              <h5 className="text-primary"> {props.profile.sgsNo}</h5>
              <div className="profile-img-container">
                {openImage && (
                  <img src={openImage} alt="lingayath weds" className="img-main mb-3" />
                )}
                <div>
                  {props.profile.profileImages.length > 1 &&
                    props.profile.profileImages.map((i) => (
                      <img
                        key={i.id}
                        src={i.imageUrl}
                        alt="lingayath weds"
                        onClick={() => setImage(i.imageUrl)}
                        className={
                          openImage === i.imageUrl ? 'img-thumb active m-2' : 'img-thumb m-2'
                        }
                      />
                    ))}
                </div>
              </div>
            </Col>
            <Col md={7}>
              <Tabs defaultActiveKey="personal" className="mb-3">
                <Tab eventKey="personal" title="Personal">
                  <div className="box p-3">
                    <h4 className="profile-heading text-dark2">Basic Details</h4>
                    <DetailsInfo label="Gender" value={props.profile.gender} />
                    <DetailsInfo
                      label="Marital Status"
                      value={props.profile.personalDetails.maritalStatus}
                    />
                    <DetailsInfo label="Age" value={calculateAge(props.profile.birthDetails.dob)} />
                    <DetailsInfo label="Caste" value={props.profile.caste} />
                    <DetailsInfo label="Subcaste" value={props.profile.subcaste} />
                    <DetailsInfo label="Bedagu" value={props.profile.bedagu} />
                  </div>

                  <div className="box my-3 p-3">
                    <h4 className="profile-heading text-dark2">Personal Details</h4>
                    <DetailsInfo label="Height" value={props.profile.personalDetails.height} />
                    <DetailsInfo label="Weight" value={props.profile.personalDetails.weight} />
                    <DetailsInfo
                      label="Blood Group"
                      value={props.profile.personalDetails.bloodGroup}
                    />
                    <DetailsInfo label="Body Type" value={props.profile.personalDetails.bodyType} />
                    <DetailsInfo
                      label="Complexion"
                      value={props.profile.personalDetails.complexion}
                    />
                    {props.profile.personalDetails.interestedInForeignVisits && (
                      <DetailsInfo
                        label="Interested in foreign visit"
                        value={props.profile.personalDetails.interestedInForeignVisits}
                      />
                    )}
                  </div>
                </Tab>
                <Tab eventKey="birth" title="Birth">
                  <div className="box my-3 p-3">
                    <h4 className="profile-heading text-dark2">Birth Details</h4>
                    <DetailsInfo label="Date of Birth" value={props.profile.birthDetails.dob} />
                    <DetailsInfo
                      label="Birth Place "
                      value={props.profile.birthDetails.birthPlace}
                    />
                    <DetailsInfo label="Birth Time" value={props.profile.birthDetails.birthTime} />
                    {props.profile.birthDetails.birthDay && (
                      <DetailsInfo label="Birth Day" value={props.profile.birthDetails.birthDay} />
                    )}
                    <DetailsInfo label="Moon Sign" value={props.profile.birthDetails.moonsign} />
                    <DetailsInfo label="Star" value={props.profile.birthDetails.star} />
                    <DetailsInfo label="Gothra" value={props.profile.birthDetails.gothra} />
                    <DetailsInfo label="Pada" value={props.profile.birthDetails.pada} />
                  </div>
                </Tab>
                <Tab eventKey="family" title="Family">
                  <div className="box my-3 p-3">
                    <h4 className="profile-heading text-dark2">Family Details</h4>
                    <DetailsInfo
                      label="Father Name"
                      value={`${props.profile.familyDetails.fatherName} (${props.profile.familyDetails.fatherAlive})`}
                    />
                    <DetailsInfo
                      label="Father Occupation"
                      value={props.profile.familyDetails.fatherOccupation}
                    />
                    <DetailsInfo
                      label="Mother Name"
                      value={`${props.profile.familyDetails.motherName} (${props.profile.familyDetails.motherAlive})`}
                    />
                    <DetailsInfo
                      label="Mother Occupation"
                      value={props.profile.familyDetails.motherOccupation}
                    />
                    <DetailsInfo
                      label="Brothers"
                      value={props.profile.familyDetails.noOfBrothers}
                    />
                    <DetailsInfo
                      label="Married Brothers"
                      value={props.profile.familyDetails.noOfBrothersMarried}
                    />
                    <DetailsInfo label="Sisters" value={props.profile.familyDetails.noOfSisters} />
                    <DetailsInfo
                      label="Married Sisters"
                      value={props.profile.familyDetails.noOfSistersMarried}
                    />
                  </div>
                  <div className="box my-3 p-3">
                    <h4 className="profile-heading text-dark2">Contact Details </h4>
                    <DetailsInfo label="District" value={props.profile.contactDetails.district} />
                    <DetailsInfo
                      label="Resident At"
                      value={props.profile.contactDetails.residentAt}
                    />
                  </div>
                </Tab>
                <Tab eventKey="education" title="Education">
                  <div className="box my-3 p-3">
                    <h4 className="profile-heading text-dark2">Education and Profession</h4>
                    <DetailsInfo
                      label="Edcation Stream"
                      value={props.profile.educationsDetails.educationStream}
                    />
                    <DetailsInfo
                      label="Qualification"
                      value={props.profile.educationsDetails.qualification}
                    />
                    <DetailsInfo
                      label="Occupation"
                      value={props.profile.educationsDetails.occupation}
                    />
                    <DetailsInfo
                      label="Working In"
                      value={props.profile.educationsDetails.workingIn}
                    />
                    <DetailsInfo
                      label="Annual Income"
                      value={props.profile.educationsDetails.annualIncome}
                    />
                  </div>
                </Tab>
                <Tab eventKey="looking" title="Looking For">
                  <div className="box my-3 p-3">
                    <h4 className="profile-heading text-dark2">Looking For</h4>
                    <DetailsInfo
                      label="Age"
                      value={
                        props.profile.lookingForDetails.ageFrom
                          ? `${props.profile.lookingForDetails.ageFrom} to ${props.profile.lookingForDetails.ageTo}`
                          : ''
                      }
                    />
                    <DetailsInfo label="Caste" value={props.profile.lookingForDetails.caste} />
                    <DetailsInfo
                      label="Subcaste"
                      value={props.profile.lookingForDetails.subCaste}
                    />
                    <DetailsInfo label="Height" value={props.profile.lookingForDetails.height} />
                    <DetailsInfo
                      label="Qualification"
                      value={props.profile.lookingForDetails.qualification}
                    />
                    <DetailsInfo
                      label="Occupation"
                      value={props.profile.lookingForDetails.occupation}
                    />
                    <DetailsInfo
                      label="Annual Income"
                      value={props.profile.lookingForDetails.annualIncome}
                    />
                    <DetailsInfo
                      label="Marital Status"
                      value={props.profile.lookingForDetails.maritalStatus}
                    />
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
interface DetailsInfoProps {
  label: string;
  value: string | number;
}
const DetailsInfo = (props: DetailsInfoProps) => {
  return (
    <div className="details-info">
      <p className="label">{props.label}</p>
      <p className="value  mx-1">:</p>
      <p className="value">{props.value}</p>
    </div>
  );
};

export default ProfileModal;
