import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { AppImages } from '../../assets/images';
import { ITestimony } from '../../core/models';
import { ProfileService } from '../../services';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function TestimonialCarousel() {
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState<ITestimony[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    cur: 1,
    max: 0,
    limit: 20,
  });

  const getImages = async (page: number = 1) => {
    setLoading(true);
    try {
      const res = await ProfileService.getTestimonials(page, 5);
      if (res.status === 200) {
        if (res.data.data) {
          setTestimonials(res.data.data);
          setPagination({
            cur: res.data.pagination_data.page,
            max: res.data.pagination_data.pages,
            limit: res.data.pagination_data.limit,
          });
        } else {
          setTestimonials([]);
          setPagination({
            cur: 1,
            max: 1,
            limit: 20,
          });
          alert(res.data.message);
        }
      } else {
        alert(res.data.error.message);
      }
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  const cardcar = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Carousel responsive={responsive} className="py-3">
        {testimonials.map((i) => (
          <div className="test-card mx-4" key={i.id}>
            {!i.youtubeLink && (
              <div
                className="test-card-img"
                style={{ backgroundImage: `url(${i.imageUrl})` }}
              ></div>
            )}
            {i.youtubeLink && (
              <Carousel responsive={cardcar} className="">
                <div
                  className="test-card-img"
                  style={{ backgroundImage: `url(${i.imageUrl})` }}
                ></div>
                <iframe
                  width="100%"
                  height="240px"
                  src={`https://www.youtube.com/embed/${i.youtubeLink.split('/').pop()}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </Carousel>
            )}
            <div className="test-card-body">
              <h5>
                {i.brideName} and {i.groomName}
              </h5>
              <p>{i.testimony}</p>
              {i.youtubeLink && (
                <Button variant="danger" className="mt-2 ">
                  Youtube <FontAwesomeIcon icon={faPlayCircle} />
                </Button>
              )}
            </div>
          </div>
        ))}
      </Carousel>
      <div className="my-3 text-center">
        <Button variant="secondary" onClick={() => navigate('/testimonials')}>
          See More
        </Button>
      </div>
    </>
  );
}

export default TestimonialCarousel;
