import { IProfile } from './models';

export const emptyProfile: IProfile = {
  id: null,
  sgsNo: '',
  name: '',
  fileNo: '',
  gender: '',
  branchDetails: {
    phone_number: '',
    branch_name: '',
    id: null,
  },
  profileStatusDetails: {
    id: null,
    profile_status: '',
  },
  caste: '',
  subcaste: '',
  bedagu: '',
  registerdDate: '',
  nextRenewalDate: '',
  birthDetails: {
    id: null,
    Dob: '',
    birthPlace: '',
    birthTime: '',
    birthDay: '',
    star: '',
    moonsign: '',
    gothra: '',
    pada: '',
    dob: '',
  },

  familyDetails: {
    id: null,
    fatherName: '',
    fatherAlive: '',
    fatherOccupation: '',
    motherName: '',
    motherAlive: '',
    motherOccupation: '',
    noOfBrothers: null,
    noOfBrothersMarried: null,
    noOfSisters: null,
    noOfSistersMarried: null,
  },

  personalDetails: {
    id: null,
    height: '',
    weight: '',
    bloodGroup: '',
    bodyType: '',
    complexion: '',
    maritalStatus: '',
    interestedInForeignVisits: '',
  },

  educationsDetails: {
    id: null,
    educationStream: '',
    qualification: '',
    occupation: '',
    workingIn: '',
    annualIncome: '',
    jobType: '',
  },

  contactDetails: {
    id: null,
    address1: '',
    address2: '',
    district: '',
    email: '',
    mobileNo1: '',
    mobileNo2: '',
    residentAt: '',
  },
  lookingForDetails: {
    id: null,
    ageFrom: null,
    ageTo: null,
    height: '',
    caste: '',
    subCaste: '',
    occupation: '',
    annualIncome: '',
    maritalStatus: '',
    educationStream: '',
    qualification: '',
  },

  paymentsDetails: [],
  profileImages: [{ id: null, imageUrl: '' }],
};
