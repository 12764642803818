import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faSquareFacebook, faSquareInstagram } from '@fortawesome/free-brands-svg-icons';
import { Col, Container, Row } from 'react-bootstrap';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="app-footer">
      <Container>
      <Row>
        <Col lg={9} className=''>
        <h3 className="logo text-light">Contact details</h3>
        </Col>
        <Col lg={3}>
        <h3 className="mb-3">Quick Links</h3>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <h4>Shimoga</h4>
          <Row>
            <Col md={{ span: 4 }}>
            <div className="d-flex">
                  <p>
                  <a href="tel:08182254130">08182-254130</a>,<br />
                    <a href="tel:+919483204130"> (+91) 9483204130</a>,<br />
                    <a href="tel:+919449618130"> (+91) 9449618130</a><br />
                    <a href="mailto:sgssssmg@gmail.com" className="footer-mail-color">
                      sgssssmg@gmail.com
                    </a>
                  </p>
                </div>
            </Col>
            <Col md={8}>
            <div className="d-flex">
                  <FontAwesomeIcon icon={faLocationDot} className="me-2" />
                  <p
                    className="pointer"
                    onClick={() =>
                      window.open(
                        'https://www.google.com/maps?ll=13.934463,75.552087&z=17&t=m&hl=en&gl=IN&mapclient=embed&cid=628824342478465190'
                      )
                    }
                  >
                    Shree Guru Shanthaveereshwara Seva Samithi(R)
                    <br />
                    Veerashaiva Vadhu Varara Anveshana Kendra, Infront of Droupadamma Temple,
                    Vijayanagar Layout,Gopala,
                    <br />
                    Shimoga
                  </p>
                </div>
            </Col>
          </Row>
        </Col>

        <Col lg={3}>
        <div className="d-flex">
                  <p>
                  <div className="mb-0">Bangalore</div>
                    <a href="tel:08023350130">080-23350130</a>,<br />
                    <a href="tel:+919480460130"> (+91) 9480460130</a>
                  </p>
                </div>
                <div className="d-flex">
                  <p>
                  <div className="mb-0">Davangere</div>
                    <a href="tel:+918050952637"> (+91) 8050952637</a>
                  </p>
                </div>  
                <div className="d-flex">
                  <p>
                  <div className="mb-0">Belgaum</div>
                    <a href="tel:+918762260130"> (+91) 8762260130</a>
                  </p>
                </div>      
        </Col>

        <Col lg={3}>
            <ul className="quick-links">
              <li onClick={() => navigate('/home')}>Home</li>
              <li onClick={() => navigate('/groom')}>Profile - Grooms</li>
              <li onClick={() => navigate('/bride')}>Profile - Brides</li>
              <li onClick={() => navigate('/about')}>About Us</li>
              <li onClick={() => navigate('/contact')}>Contact Us</li>
              {/* <li onClick={() => navigate('/registration')}>Registration</li> */}
              <li onClick={() => navigate('/privacy-policy')}>Privacy Policy</li>
            </ul>
          </Col>
      </Row>

                    
      </Container>

      <hr />
      <div>
        <div className="sb-footer-below">
          <div />
          <div className="sb-footer-copright">
            <p>Copyright @{new Date().getFullYear()} All Rights Reserved</p>
            <p>
              Designed by{'  '}
              <a
                className="footer-link"
                href="https://www.ekathvainnovations.com/index"
                target="_blank"
              >
                Ekathva Innovations
              </a>
            </p>
          </div>
          <div />
        </div>
      </div>
    </div>
  );
};

export default Footer;
