import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { IProfile } from '../../core/models';
import { emptyProfile } from '../../core/data';
import { calculateAge } from '../../services/utils';
import { useParams } from 'react-router-dom';
import { ProfileService } from '../../services';
import { Loading } from '../../components';
import { FindType } from '../../core/enum';

const ProfileDetails = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState<IProfile>(emptyProfile);
  const [loading, setLoading] = useState<boolean>(false);
  const [openImage, setImage] = useState('');
  const getProfile = async (type: FindType) => {
    setLoading(true);
    try {
      const res =
        type === FindType.ID
          ? await ProfileService.getProfile(Number(id))
          : await ProfileService.getProfileFno(decodeURIComponent(id));
      if (res.status === 200) {
        if (res.data.data) {
          console.log(res.data.data);
          setProfile(res.data.data);
          if (res.data?.data?.profileImages?.length > 0) {
            setImage(res.data.data.profileImages[0].imageUrl);
          }
        }
      } else {
        alert(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes('/files/')) {
      getProfile(FindType.FILE);
    } else {
      getProfile(FindType.ID);
    }
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Container className="h90">
      {profile?.id && !loading && (
        <Row className="my-3">
          <Col md={5}>
            <h3>{profile.fileNo}</h3>
            <h5 className="text-primary"> {profile.sgsNo}</h5>
            <div className="profile-img-container">
              {openImage && <img src={openImage} alt="lingayath weds" className="img-main mb-3" />}
              <div>
                {profile.profileImages.length > 1 &&
                  profile.profileImages.map((i) => (
                    <img
                      key={i.id}
                      src={i.imageUrl}
                      alt="lingayath weds"
                      onClick={() => setImage(i.imageUrl)}
                      className={
                        openImage === i.imageUrl ? 'img-thumb active m-2' : 'img-thumb m-2'
                      }
                    />
                  ))}
              </div>
            </div>
          </Col>
          <Col md={7}>
            <Tabs defaultActiveKey="personal" className="mb-3">
              <Tab eventKey="personal" title="Personal">
                <div className="box p-3">
                  <h4 className="profile-heading text-dark2">Basic Details</h4>
                  <DetailsInfo label="Gender" value={profile.gender} />
                  <DetailsInfo
                    label="Marital Status"
                    value={profile.personalDetails.maritalStatus}
                  />
                  <DetailsInfo label="Age" value={calculateAge(profile.birthDetails.dob)} />
                  <DetailsInfo label="Caste" value={profile.caste} />
                  <DetailsInfo label="Subcaste" value={profile.subcaste} />
                  <DetailsInfo label="Bedagu" value={profile.bedagu} />
                </div>

                <div className="box my-3 p-3">
                  <h4 className="profile-heading text-dark2">Personal Details</h4>
                  <DetailsInfo label="Height" value={profile.personalDetails.height} />
                  <DetailsInfo label="Weight" value={profile.personalDetails.weight} />
                  <DetailsInfo label="Blood Group" value={profile.personalDetails.bloodGroup} />
                  <DetailsInfo label="Body Type" value={profile.personalDetails.bodyType} />
                  <DetailsInfo label="Complexion" value={profile.personalDetails.complexion} />
                  {profile.personalDetails.interestedInForeignVisits && (
                    <DetailsInfo
                      label="Interested in foreign visit"
                      value={profile.personalDetails.interestedInForeignVisits}
                    />
                  )}
                </div>
              </Tab>
              <Tab eventKey="birth" title="Birth">
                <div className="box my-3 p-3">
                  <h4 className="profile-heading text-dark2">Birth Details</h4>
                  <DetailsInfo label="Date of Birth" value={profile.birthDetails.dob} />
                  <DetailsInfo label="Birth Place " value={profile.birthDetails.birthPlace} />
                  <DetailsInfo label="Birth Time" value={profile.birthDetails.birthTime} />
                  {profile.birthDetails.birthDay && (
                    <DetailsInfo label="Birth Day" value={profile.birthDetails.birthDay} />
                  )}
                  <DetailsInfo label="Moon Sign" value={profile.birthDetails.moonsign} />
                  <DetailsInfo label="Star" value={profile.birthDetails.star} />
                  <DetailsInfo label="Gothra" value={profile.birthDetails.gothra} />
                  <DetailsInfo label="Pada" value={profile.birthDetails.pada} />
                </div>
              </Tab>
              <Tab eventKey="family" title="Family">
                <div className="box my-3 p-3">
                  <h4 className="profile-heading text-dark2">Family Details</h4>
                  <DetailsInfo
                    label="Father"
                    value={`${profile.familyDetails.fatherName} (${profile.familyDetails.fatherAlive})`}
                  />
                  <DetailsInfo
                    label="Father Occupation"
                    value={profile.familyDetails.fatherOccupation}
                  />
                  <DetailsInfo
                    label="Mother"
                    value={`${profile.familyDetails.motherName} (${profile.familyDetails.motherAlive})`}
                  />
                  <DetailsInfo
                    label="Mother Occupation"
                    value={profile.familyDetails.motherOccupation}
                  />
                  <DetailsInfo label="Brothers" value={profile.familyDetails.noOfBrothers} />
                  <DetailsInfo
                    label="Married Brothers"
                    value={profile.familyDetails.noOfBrothersMarried}
                  />
                  <DetailsInfo label="Sisters" value={profile.familyDetails.noOfSisters} />
                  <DetailsInfo
                    label="Married Sisters"
                    value={profile.familyDetails.noOfSistersMarried}
                  />
                </div>
                <div className="box my-3 p-3">
                  <h4 className="profile-heading text-dark2">Contact Details </h4>
                  <DetailsInfo label="District" value={profile.contactDetails.district} />
                  <DetailsInfo label="Resident At" value={profile.contactDetails.residentAt} />
                  <DetailsInfo label="Contact No" value={profile.branchDetails.phone_number} />
                </div>
              </Tab>
              <Tab eventKey="education" title="Education">
                <div className="box my-3 p-3">
                  <h4 className="profile-heading text-dark2">Education and Profession</h4>
                  <DetailsInfo
                    label="Edcation Stream"
                    value={profile.educationsDetails.educationStream}
                  />
                  <DetailsInfo
                    label="Qualification"
                    value={profile.educationsDetails.qualification}
                  />
                  <DetailsInfo label="Occupation" value={profile.educationsDetails.occupation} />
                  <DetailsInfo label="Working In" value={profile.educationsDetails.workingIn} />
                  <DetailsInfo
                    label="Annual Income"
                    value={profile.educationsDetails.annualIncome}
                  />
                  <DetailsInfo label="Job Type" value={profile.educationsDetails.jobType} />
                </div>
              </Tab>
              <Tab eventKey="looking" title="Looking For">
                <div className="box my-3 p-3">
                  <h4 className="profile-heading text-dark2">Looking For</h4>
                  <DetailsInfo
                    label="Age"
                    value={
                      profile.lookingForDetails.ageFrom
                        ? `${profile.lookingForDetails.ageFrom} to ${profile.lookingForDetails.ageTo}`
                        : ''
                    }
                  />
                  <DetailsInfo label="Caste" value={profile.lookingForDetails.caste} />
                  <DetailsInfo label="Subcaste" value={profile.lookingForDetails.subCaste} />
                  <DetailsInfo label="Height" value={profile.lookingForDetails.height} />
                  <DetailsInfo
                    label="Qualification"
                    value={profile.lookingForDetails.qualification}
                  />
                  <DetailsInfo label="Occupation" value={profile.lookingForDetails.occupation} />
                  <DetailsInfo
                    label="Annual Income"
                    value={profile.lookingForDetails.annualIncome}
                  />
                  <DetailsInfo
                    label="Marital Status"
                    value={profile.lookingForDetails.maritalStatus}
                  />
                </div>
              </Tab>
            </Tabs>
            {/* <h3 className="text-primary">Contact No: <a href={'tel:' + profile.branchDetails.phone_number} className="text-primary">{profile.branchDetails.phone_number}</a></h3> */}
          </Col>
        </Row>
      )}
      <Loading show={loading} />
      {!loading && !profile.id && (
        <h3 className="text-secondary text-center my-5 py-5">Profile not found</h3>
      )}
    </Container>
  );
};
interface DetailsInfoProps {
  label: string;
  value: string | number;
}
const DetailsInfo = (props: DetailsInfoProps) => {
  return (
    <div className="details-info">
      <p className="label">{props.label}</p>
      <p className="value  mx-1">:</p>
      <p className="value">{props.value}</p>
    </div>
  );
};

export default ProfileDetails;
