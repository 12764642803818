import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Row } from 'react-bootstrap';
import BranchCarousel from './BranchCarousel';

const ContactPage = () => {
  return (
    <>
      <h1 className="heading bg-light text-center py-3">Contact Details</h1>
      <Container>
        <Row className="my-5 g-0">
          <Col lg={6}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7744.807068596414!2d75.55007030208152!3d13.934561790091708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbba8b9c35faf15%3A0x8ba0868678c28a6!2sShree%20Guru%20Shanthaveereshwara%20Seva%20Samithi!5e0!3m2!1sen!2sin!4v1703156383245!5m2!1sen!2sin"
              style={{ width: '100%', height: '100%', border: '0px' }}
              loading="lazy"
            ></iframe>
          </Col>
          <Col lg={6} className="bg-dark text-light p-2 p-md-5">
            <h2 className="mb-4">Contact - Head Office</h2>
            <div className="d-flex">
              <FontAwesomeIcon icon={faLocationDot} size="xl" className="me-3" />
              <div>
                <h6> Shree Guru Shanthaveereshwara Seva Samithi(R)</h6>
                <p>
                  Veerashaiva Vadhu Varara Anveshana Kendra,
                  <br /> Infront of Droupadamma Temple,
                  <br /> Vijayanagar Layout,
                  <br /> Gopala,
                  <br /> Shimoga, Karnataka,
                  <br /> India
                </p>
              </div>
            </div>
            <div className="d-flex">
              <FontAwesomeIcon icon={faPhone} size="xl" className="me-3" />
              <p>
                <a href="tel:08182254130">08182-254130</a>
                <br />
                <a href="tel:+919483204130"> (+91) 9483204130</a>
                <br />
                <a href="tel:+919449618130"> (+91) 9449618130</a>
              </p>
            </div>
            <div className="d-flex">
              <FontAwesomeIcon icon={faEnvelope} size="xl" className="me-3" />
              <div>
                <a href="mailto:sgssssmg@gmail.com">sgssssmg@gmail.com</a>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="my-4">
          <h2 className="heading-alt text-center">Franchise</h2>
          <Col>
            <BranchCarousel />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactPage;
