export const Castes: { id: number; caste: string }[] = [
  { id: 1, caste: 'Hindu - Jangama' },
  { id: 2, caste: 'Hindu - Lingayatha' },
  { id: 3, caste: 'Hindu - Sadu Lingayath' },
  { id: 4, caste: 'Second Marriage' },
];
export const Moonsigns = [
  { id: 2, moon_sign: 'Mesha (Aries)' },
  { id: 3, moon_sign: 'Vrishabha (Taurus)' },
  { id: 4, moon_sign: 'Mithuna (Gemini)' },
  { id: 5, moon_sign: 'Karkataka (Cancer)' },
  { id: 6, moon_sign: 'Simha (Leo)' },
  { id: 7, moon_sign: 'Kanya (Virgo)' },
  { id: 8, moon_sign: 'Tula (Libra)' },
  { id: 9, moon_sign: 'Vrischika (Scorpio)' },
  { id: 10, moon_sign: 'Dhanu (Sagittarious)' },
  { id: 11, moon_sign: 'Makara (Capricorn)' },
  { id: 12, moon_sign: 'Kumbha (Aquarious)' },
  { id: 13, moon_sign: 'Meena (Prisces)' },
  { id: 1, moon_sign: 'Do not know' },
];
export const Stars: { id: number; star: string }[] = [
  { id: 1, star: 'Dont know' },
  { id: 2, star: 'Uttarashada' },
  { id: 3, star: 'Shravana' },
  { id: 4, star: 'Dhanista' },
  { id: 5, star: 'Shatadisha' },
  { id: 6, star: 'Poorvabhadrapada' },
  { id: 7, star: 'Uttarabhadrapada' },
  { id: 8, star: 'Revathi' },
  { id: 9, star: 'Ashwini' },
  { id: 10, star: 'Bharani' },
  { id: 11, star: 'Kartika' },
  { id: 12, star: 'Rohini' },
  { id: 13, star: 'Mrugashira' },
  { id: 14, star: 'Aridra' },
  { id: 15, star: 'Punarvasu' },
  { id: 16, star: 'Pushya' },
  { id: 17, star: 'Ashlesha' },
  { id: 18, star: 'Magha' },
  { id: 19, star: 'Hubba' },
  { id: 20, star: 'Uttara' },
  { id: 21, star: 'Chitta' },
  { id: 22, star: 'Swati' },
  { id: 23, star: 'Vishaka' },
  { id: 24, star: 'Anuradha' },
  { id: 25, star: 'Jeshta' },
  { id: 26, star: 'Moola' },
  { id: 27, star: 'Poorvashada' },
  { id: 28, star: 'Asta' },
];
export const Padas: { id: number; pada: string }[] = [
  { id: 2, pada: 'Pada 1' },
  { id: 3, pada: 'Pada 2' },
  { id: 4, pada: 'Pada 3' },
  { id: 5, pada: 'Pada 4' },
  { id: 1, pada: 'Do not know' },
];
export const Gothras: { id: number; gothra: string }[] = [
  { id: 1, gothra: 'Do not know' },
  { id: 2, gothra: 'Gothra 1' },
  { id: 3, gothra: 'Gothra 2' },
];
export const EducationStream: { id: number; education_stream: string }[] = [
  { id: 1, education_stream: 'ENGINEERING' },
  { id: 2, education_stream: 'MEDICAL' },
  { id: 3, education_stream: 'NURSING' },
  { id: 4, education_stream: 'COMMERCE' },
  { id: 5, education_stream: 'ARTS' },
  { id: 6, education_stream: 'SCIENCE' },
  { id: 7, education_stream: 'DIPLOMA' },
  { id: 8, education_stream: 'PUC' },
  { id: 9, education_stream: 'SSLC' },
  { id: 10, education_stream: 'NOT APPLICABLE' },
];
export const Weights: { id: number; weight: string }[] = [
  { id: 1, weight: 'Do no Matter' },
  { id: 2, weight: '30 Kg - 35 Kg' },
  { id: 3, weight: '36 Kg - 40 Kg' },
  { id: 4, weight: '41 Kg - 45 Kg' },
  { id: 5, weight: '46 Kg - 50 Kg' },
  { id: 6, weight: '51 Kg - 55 Kg' },
  { id: 7, weight: '56 Kg - 60 Kg' },
  { id: 8, weight: '61 Kg - 65 Kg' },
  { id: 9, weight: '66 Kg - 70 Kg' },
  { id: 10, weight: '71 Kg - 80 Kg' },
  { id: 11, weight: '81 Kg - 90 Kg' },
  { id: 12, weight: '91 Kg - 100 Kg' },
  { id: 13, weight: '101 Kg - 110 Kg' },
  { id: 14, weight: '111 Kg - 120 Kg' },
  { id: 15, weight: 'Above 120 Kg' },
];
export const Heights: { id: number; height: string }[] = [
  { id: 1, height: 'Below 3ft 6in' },
  { id: 2, height: '3ft 7in' },
  { id: 3, height: '3ft 8in' },
  { id: 4, height: '3ft 9in' },
  { id: 5, height: '3ft 10in' },
  { id: 6, height: '3ft 11in' },
  { id: 7, height: '4ft 0in' },
  { id: 8, height: '4ft 1in' },
  { id: 9, height: '4ft 2in' },
  { id: 10, height: '4ft 3in' },
  { id: 11, height: '4ft 4in' },
  { id: 12, height: '4ft 5in' },
  { id: 13, height: '4ft 6in' },
  { id: 14, height: '4ft 7in' },
  { id: 15, height: '4ft 8in' },
  { id: 16, height: '4ft 9in' },
  { id: 17, height: '4ft 10in' },
  { id: 18, height: '4ft 11in' },
  { id: 19, height: '5ft 0in' },
  { id: 20, height: '5ft 1in' },
  { id: 21, height: '5ft 2in' },
  { id: 22, height: '5ft 3in' },
  { id: 23, height: '5ft 4in' },
  { id: 24, height: '5ft 5in' },
  { id: 25, height: '5ft 6in' },
  { id: 26, height: '5ft 7in' },
  { id: 27, height: '5ft 8in' },
  { id: 28, height: '5ft 9in' },
  { id: 29, height: '5ft 10in' },
  { id: 30, height: '5ft 11in' },
  { id: 31, height: '6ft 0in' },
  { id: 32, height: '6ft 1in' },
  { id: 33, height: '6ft 2in' },
  { id: 34, height: '6ft 3in' },
  { id: 35, height: '6ft 4in' },
  { id: 36, height: '6ft 5in' },
  { id: 37, height: '6ft 6in' },
  { id: 38, height: '6ft 7in' },
  { id: 39, height: '6ft 8in' },
  { id: 40, height: '6ft 9in' },
  { id: 41, height: '6ft 10in' },
  { id: 42, height: '6ft 11in' },
  { id: 43, height: '7ft 0in' },
  { id: 44, height: '7ft 1in' },
  { id: 45, height: '7ft 2in' },
  { id: 46, height: '7ft 3in' },
  { id: 47, height: '7ft 4in' },
  { id: 48, height: '7ft 5in' },
  { id: 49, height: 'Above 7ft 5in' },
];
export const BloodGroup: { id: number; blood_group: string }[] = [
  { id: 2, blood_group: 'O +ve' },
  { id: 3, blood_group: 'O -ve' },
  { id: 4, blood_group: 'A +ve' },
  { id: 5, blood_group: 'A -ve' },
  { id: 6, blood_group: 'B +ve' },
  { id: 7, blood_group: 'B -ve' },
  { id: 8, blood_group: 'AB +ve' },
  { id: 9, blood_group: 'AB -ve' },
  { id: 1, blood_group: 'Does not Matter' },
];
export const BodyType: { id: number; body_type: string }[] = [
  { id: 1, body_type: 'Do not Know' },
  { id: 2, body_type: 'Slim' },
  { id: 3, body_type: 'Average' },
  { id: 4, body_type: 'Athletic' },
  { id: 5, body_type: 'Heavy' },
];
export const MaritalStatus: { id: number; marital_status: string }[] = [
  { id: 1, marital_status: 'Unmarried' },
  { id: 2, marital_status: 'Widow' },
  { id: 3, marital_status: 'Widower' },
  { id: 4, marital_status: 'Divorcee' },
];
export const Complexions: { id: number; complexion: string }[] = [
  { id: 1, complexion: 'Does not Matter' },
  { id: 2, complexion: 'Very fair' },
  { id: 3, complexion: 'Fair' },
  { id: 4, complexion: 'Whitish' },
  { id: 5, complexion: 'Whitish Medium' },
  { id: 6, complexion: 'Whitish Brown' },
  { id: 7, complexion: 'Dark' },
];
export const Districts: { id: number; district: string }[] = [
  { id: 1, district: 'Bagalkot' },
  { id: 2, district: 'Ballari (Bellary)' },
  { id: 3, district: 'Belagavi (Belgaum)' },
  { id: 4, district: 'Bengaluru Rural' },
  { id: 5, district: 'Bengaluru Urban (Bangalore)' },
  { id: 6, district: 'Bidar' },
  { id: 7, district: 'Chamarajanagar' },
  { id: 8, district: 'Chikballapur' },
  { id: 9, district: 'Chikkamagaluru (Chikmagalur)' },
  { id: 10, district: 'Chitradurga' },
  { id: 11, district: 'Dakshina Kannada' },
  { id: 12, district: 'Davanagere' },
  { id: 13, district: 'Dharwad' },
  { id: 14, district: 'Gadag' },
  { id: 15, district: 'Hassan' },
  { id: 16, district: 'Haveri' },
  { id: 17, district: 'Kalaburagi (Gulbarga)' },
  { id: 18, district: 'Kodagu (Coorg)' },
  { id: 19, district: 'Kolar' },
  { id: 20, district: 'Koppal' },
  { id: 21, district: 'Mandya' },
  { id: 22, district: 'Mysuru (Mysore)' },
  { id: 23, district: 'Raichur' },
  { id: 24, district: 'Ramanagara' },
  { id: 25, district: 'Shivamogga (Shimoga)' },
  { id: 26, district: 'Tumakuru (Tumkur)' },
  { id: 27, district: 'Udupi' },
  { id: 28, district: 'Uttara Kannada (Karwar)' },
  { id: 29, district: 'Vijayapura (Bijapur)' },
  { id: 30, district: 'Yadgir' },
  { id: 31, district: 'Vijayanagara' },
];
export const AnnualIncome: { id: number; annual_income: string }[] = [
  { id: 1, annual_income: 'Does not Matter' },
  { id: 2, annual_income: '50000 to 1 Lakh' },
  { id: 3, annual_income: '1 Lakh to 1.5 Lakhs' },
  { id: 4, annual_income: '1.5 Lakhs to 2 Lakhs' },
  { id: 5, annual_income: '2 Lakhs to 2.5 Lakhs' },
  { id: 6, annual_income: '2.5 Lakhs to 3 Lakhs' },
  { id: 7, annual_income: '3 Lakhs to 4 Lakhs' },
  { id: 8, annual_income: '4 Lakhs to 5 Lakhs' },
  { id: 9, annual_income: '5 Lakhs to 6 Lakhs' },
  { id: 10, annual_income: '6 Lakhs to 7 Lakhs' },
  { id: 11, annual_income: '7 Lakhs to 8 Lakhs' },
  { id: 12, annual_income: '8 Lakhs to 9 Lakhs' },
  { id: 13, annual_income: '9 Lakhs to 10 Lakhs' },
  { id: 14, annual_income: '10 Lakhs to 11 Lakhs' },
  { id: 15, annual_income: '11 Lakhs to 12 Lakhs' },
  { id: 16, annual_income: '12 Lakhs to 15 Lakhs' },
  { id: 17, annual_income: '15 Lakhs to 20 Lakhs' },
  { id: 18, annual_income: '20 Lakhs to 25 Lakhs' },
  { id: 19, annual_income: '25 Lakhs to 30 Lakhs' },
  { id: 20, annual_income: '30 Lakhs to 35 Lakhs' },
  { id: 21, annual_income: '35 Lakhs to 40 Lakhs' },
  { id: 22, annual_income: '40 Lakhs to 45 Lakhs' },
  { id: 23, annual_income: '45 Lakhs to 50 Lakhs' },
  { id: 24, annual_income: '50 Lakhs to 60 Lakhs' },
  { id: 25, annual_income: '60 Lakhs and Above' },
];
export const JobTypes = [
  'Government',
  'Private',
  'Business',
  'Engineering',
  'Doctor',
  'Bank Employee',
  'Self Employed',
  'NRI',
  'Agriculturist',
  'None of the Above',
];
export const DaysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
