import React, { useEffect } from 'react';
import { Navigate, RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';

import { Footer, Header } from './components';
import {
  AboutPage,
  BridePage,
  ContactPage,
  Gallery,
  GroomPage,
  HomePage,
  PrivacyPolicyPage,
  ProfileDetails,
  RegistrationPage,
  TestimonialsPage,
} from './pages';
import { analytics } from './services/firebase';
import { getAnalytics, logEvent } from 'firebase/analytics';

function Layout() {
  useEffect(() => {
    getAnalytics();
    logEvent(analytics, 'visit');
  }, []);
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

const router = createBrowserRouter(
  [
    {
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Navigate to={'home'} />,
        },
        {
          path: '/',
          element: <Navigate to={'home'} />,
        },
        {
          path: '/home',
          element: <HomePage />,
        },
        {
          path: '/groom',
          element: <GroomPage />,
        },
        {
          path: '/bride',
          element: <BridePage />,
        },
        {
          path: '/about',
          element: <AboutPage />,
        },
        {
          path: '/contact',
          element: <ContactPage />,
        },
        // {
        //   path: '/registration',
        //   element: <Registration />,
        // },
        {
          path: '/privacy-policy',
          element: <PrivacyPolicyPage />,
        },
        {
          path: '/registration',
          element: <RegistrationPage />,
        },
        {
          path: '/gallery',
          element: <Gallery />,
        },
        {
          path: '/testimonials',
          element: <TestimonialsPage />,
        },
        {
          path: '/profiles/:id',
          element: <ProfileDetails />,
        },
        {
          path: '/files/:id',
          element: <ProfileDetails />,
        },
      ],
    },
  ],
  { basename: '' }
);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
