import React from 'react';
import { Card, CardTitle, CardBody, CardText } from 'reactstrap';
import { AppImages } from '../../assets/images';
import { Col, Container, Row } from 'react-bootstrap';
import TestimonialCarousel from '../Home/TestimonialCarousel';

const AboutPage = () => {
  return (
    <>
      <h1 className="heading bg-light py-4 mb-2 text-center">About Us</h1>
      <Container>
        <Row className="my-50">
          <Col md={12}>
            <p>
              ಆತ್ಮೀಯ ವೀರಶೈವ ಸಮಾಜ ಬಾಂಧವರೇ ನಮ್ಮ ವೀರಶೈವ / ಲಿಂಗಾಯತ ವಧು-ವರರ ಅನ್ವೇಷಣ ಕೇಂದ್ರ 1999ರಲ್ಲಿ
              ಪ್ರಾರಂಭವಾಗಿ ಕಳೆದ 25 ವರ್ಷಗಳಿಂದ ವಿವಾಹ ಸಂಬಂಧಗಳನ್ನು ಏರ್ಪಡಿಸುವಲ್ಲಿ ಯಶಸ್ವಿಯಾಗಿ
              ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿರುವ ಕೇಂದ್ರವಾಗಿದೆ.
            </p>
            <p>
              ಶಿವಮೊಗ್ಗದಲ್ಲಿ ಪ್ರಧಾನ ಕಚೇರಿಯನ್ನು ಹೊಂದಿದ್ದು, ಕರ್ನಾಟಕ ರಾಜ್ಯದ ಹುಬ್ಬಳ್ಳಿ, ದಾವಣಗೆರೆ, ಸಾಗರ,
              ಚಿತ್ರದುರ್ಗ, ತುಮಕೂರು, ಬೆಂಗಳೂರು, ಮೈಸೂರು, ಬೆಳಗಾವಿ, ವಿಜಯಪುರ ಜಿಲ್ಲೆಗಳಲ್ಲಿ ಶಾಖ ಕಚೇರಿಗಳನ್ನು
              ಹೊಂದಿದೆ.
            </p>
            <p>
              ನಮ್ಮಲ್ಲಿ ಎಲ್ಲಾ ರೀತಿಯ ಸರ್ಕಾರಿ ನೌಕರರು, ವಾಣಿಜ್ಯೋದ್ಯಮಿಗಳು, ವೈದ್ಯರು, ಇಂಜಿನಿಯರ್ ಗಳು, ಬ್ಯಾಂಕ್
              ಉದ್ಯೋಗಿಗಳು, ಇತರ ಪದವೀಧರರು ಹಾಗೂ ವಿವಾಹ ವಿಚ್ಛೇದಿತರು, ವಿಧುರ / ವಿಧವೆಯರು ಸೇರಿದಂತೆ ಎಲ್ಲಾ ತರಹದ
              ವಧು ವರರು ಲಭ್ಯರಿದ್ದಾರೆ.
            </p>
            <ul className="checkmark-list">
              <li>1999ರಲ್ಲಿ ಪ್ರಾರಂಭವಾಗಿದೆ</li>
              <li>100ಕ್ಕೂ ಹೆಚ್ಚು ಸಮಾವೇಶಗಳು</li>
              <li>1000ಕ್ಕೂ ಹೆಚ್ಚು ವಿವಾಹ ಮಾಡಿಸಲಾಗಿದೆ</li>
              <li>ವಿಧವಾ / ವಿಧವೆಯರಿಗೆ ಸರಳವಾಗಿ ಮದುವೆ ಮಾಡಿಸಲಾಗಿದೆ</li>
              <li>ವಿಶೇಷವಾಗಿ ವಿಕಲಚೇತನರಿಗೂ ಕೂಡ ಮದುವೆ ಮಾಡಿಸಿದ ಕೀರ್ತಿ ನಮ್ಮ ಸಂಸ್ಥೆಗಿದೆ.</li>
            </ul>
          </Col>
        </Row>
        <Row className="my-50">
          <Col md={12} className="text-center mb-4">
            <h2 className="heading-alt">Vision and Mission of the Trust</h2>
            <p className="sub-heading">
              {' '}
              To provide cost effective services to the society and work for the betterment of the
              society and our country
            </p>
          </Col>
        </Row>
        <Row className="my-50">
          <Col md={12} className="text-center">
            <h2 className="heading-alt mb-4"> Our Services</h2>
            <p className="sub-heading">
              Shree Guru Shanthaveereshwara Seva Samithi (R), Shimoga is not only serving in
              Matrimonials also has the following
            </p>
          </Col>
        </Row>
        <Row className="gx-2">
          <Col md={3} className="my-2 px-2 card">
            <img src={AppImages.weds} className="home-service-logo" />
            <CardBody>
              <CardTitle tag="h5">Lingayathweds.com</CardTitle>
              <CardText>
                {' '}
                A unique matrimonial services exclusively designed for lingayath community world
                wide
              </CardText>
            </CardBody>
          </Col>
          <Col md={3} className="my-2 px-2 card">
            <img src={AppImages.travells} className="home-service-logo" />
            <CardBody>
              <CardTitle tag="h5">SGS Tours and Travels</CardTitle>
              <CardText>
                Tours services provide at all levels of the regions namely state, country and
                abroad. A customized tour services are also provided
              </CardText>
            </CardBody>
          </Col>
          <Col md={3} className="my-2 px-2 card">
            <img src={AppImages.funds} className="home-service-logo" />
            <CardBody>
              <CardTitle tag="h5">SGS Chit Funds</CardTitle>
              <CardText>
                {' '}
                A standard firm can compete many existing chit fund services in providing
                unbelievable benefits to the customers
              </CardText>
            </CardBody>
          </Col>
          <Col md={3} className="my-2 px-2 card">
            <img src={AppImages.developers} className="home-service-logo" />
            <CardBody>
              <CardTitle tag="h5">SGS developers</CardTitle>
              <CardText>Launching soon</CardText>
            </CardBody>
          </Col>
        </Row>
        <Row className="my-50">
          <Col md={12} classNam="text-center mb-4">
            <h2 className="heading-alt text-center"> Our Happy Couples</h2>
            <TestimonialCarousel />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutPage;
