export const AppImages = {
  logo: require('./logo.png'),
  landingImage1: require('./landing1.jpg'),
  landingImage2: require('./landing2.jpg'),
  landingImage3: require('./landing3.jpg'),
  landingImage4: require('./landing4.jpg'),
  landingImage5: require('./landing5.jpg'),
  landingImage6: require('./landing6.jpg'),
  coupleImage_1: require('./CoupleImage_1.png'),
  coupleImage_2: require('./CoupleImage_2.png'),
  coupleImage_3: require('./CoupleImage_3.png'),
  coupleImage_4: require('./CoupleImage_4.jpg'),
  weds: require('./rings.png'),
  travells: require('./flight.png'),
  funds: require('./capital.png'),
  developers: require('./building.png'),
  profilePlaceholder: require('./profile_placeholder.jpg'),
};
export const AppIcons = {
  form: require('./icon1.jpg'),
  community: require('./icon2.jpg'),
};
