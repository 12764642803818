import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import { AppPagination, Filter, ProfileCard, ProfileModal } from '../../components';
import { emptyProfile } from '../../core/data';
import { IFilterHome, IProfileShort } from '../../core/models';
import { FilterType } from '../../core/enum';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProfileService } from '../../services';
import { objectToQueryString } from '../../services/utils';

const GroomPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [profiles, setProfiles] = useState<IProfileShort[]>([]);
  const [pagination, setPagination] = useState({
    cur: 1,
    max: 0,
    limit: 20,
  });
  const [params, setParams] = useSearchParams();
  const [modal, setModal] = useState({
    show: false,
    data: emptyProfile,
  });
  const onFilter = (e: IFilterHome) => {
    const query = objectToQueryString(e);
    navigate(`/groom?${query}`);
  };
  const getProfiles = async () => {
    setLoading(true);
    try {
      const ageTo = Number(params.get('ageTo'));
      const ageFrom = Number(params.get('ageFrom'));
      const caste = params.get('caste');
      const qualification = params.get('qualification');
      const page = Number(params.get('page'));
      const jobtitle = params.get('jobtitle');

      const res = await ProfileService.getFilteredProfiles(
        'male',
        ageFrom,
        ageTo,
        caste,
        qualification,
        jobtitle,
        20,
        page
      );
      if (res.status === 200) {
        if (res.data.data) {
          setProfiles(res.data.data);
          setPagination({
            cur: res.data.pagination_data.page,
            max: res.data.pagination_data.pages,
            limit: res.data.pagination_data.limit,
          });
        } else {
          setProfiles([]);
          setPagination({
            cur: 1,
            max: 1,
            limit: 20,
          });
          alert(res.data.message);
        }
      } else {
        alert(res.data.error.message);
      }
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };
  const onPageChange = (n: number) => {
    const e = {
      ageTo: Number(params.get('ageTo')),
      ageFrom: Number(params.get('ageFrom')),
      caste: params.get('caste'),
      qualification: params.get('qualification'),
      page: n,
    };
    const query = objectToQueryString(e);
    navigate(`/groom?${query}`);
  };

  useEffect(() => {
    getProfiles();
  }, [window.location.search]);

  return (
    <>
      <h1 className="heading bg-light mb-0 text-center py-4">Grooms</h1>
      <Filter onFilter={(e) => onFilter(e)} type={FilterType.BRIDE} />
      {profiles.length > 0 && (
        <div className="d-block d-md-none">
          <AppPagination
            cur={pagination.cur}
            max={pagination.max}
            limit={pagination.limit}
            onPageChange={(n) => onPageChange(n)}
          />
        </div>
      )}
      <Container className="my-3">
        <Row>
          {!loading &&
            profiles.map((i: IProfileShort) => (
              <Col md={3} className="px-2 my-2" key={i.id}>
                <ProfileCard
                  profile={i}
                  onClick={(p) => {
                    navigate('/profiles/' + p.id);
                  }}
                />
              </Col>
            ))}
        </Row>
        {loading && (
          <div className="my-5 py-4 d-flex align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" />{' '}
            <span className="fs-5 ms-1">Loading...</span>
          </div>
        )}
      </Container>

      {profiles.length === 0 && !loading && (
        <h5 className="text-secondary my-5 py-5 text-center">No Profiles found</h5>
      )}
      {profiles.length > 0 && (
        <div className="d-none d-md-block">
          <AppPagination
            cur={pagination.cur}
            max={pagination.max}
            limit={pagination.limit}
            onPageChange={(n) => onPageChange(n)}
          />
        </div>
      )}
    </>
  );
};

export default GroomPage;
