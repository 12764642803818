import React from 'react';
import { IProfileShort } from '../core/models';
import { Button } from 'react-bootstrap';
import { AppImages } from '../assets/images';
interface ProfileCardProps {
  profile: IProfileShort;
  onClick: (p: IProfileShort) => void;
}
const ProfileCard = (props: ProfileCardProps) => {
  return (
    <div className="carousel-card">
      <div
        className="carousel-img"
        style={
          props.profile.profileImages.length > 0
            ? {
                backgroundImage: `url(${props.profile.profileImages[0].imageUrl})`,
              }
            : { backgroundImage: `url(${AppImages.profilePlaceholder})` }
        }
      ></div>
      <div className="carousel-body">
        <h4>{props.profile.fileNo}</h4>
        <p className="bold text-primary arial">{props.profile.sgsNo}</p>
        <Button onClick={() => props.onClick(props.profile)} variant="secondary">
          More Info
        </Button>
      </div>
    </div>
  );
};
export default ProfileCard;
