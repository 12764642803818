import React, { useEffect, useState } from 'react';
import { Pagination, Button, InputGroup, Form, Container, Row, Col } from 'react-bootstrap';

interface AppPaginationProps {
  cur: number;
  max: number;
  limit: number;
  onPageChange: (n: number) => void;
}

const AppPagination = (props: AppPaginationProps) => {
  const [inputPage, setPage] = useState<number>(undefined);
  useEffect(() => {
    setPage(props.cur);
  }, [props]);
  return (
    <Container>
      <Row className="my-2">
        <Col md={6}>
          <Pagination className="justify-content-center">
            <Pagination.First
              disabled={props.cur === 1}
              onClick={() => {
                props.onPageChange(1);
              }}
            />
            <Pagination.Prev
              onClick={() => {
                props.onPageChange(props.cur - 1);
              }}
              disabled={props.cur === 1}
            />
            <Pagination.Item>
              Page {props.cur} of {props.max}
            </Pagination.Item>
            <Pagination.Next
              onClick={() => {
                props.onPageChange(props.cur + 1);
              }}
              disabled={props.cur === props.max}
            />
            <Pagination.Last
              onClick={() => {
                props.onPageChange(props.max);
              }}
              disabled={props.cur === props.max}
            />
          </Pagination>
        </Col>
        <Col md={6} className="text-center text-md-start">
          <InputGroup className="page-input">
            <Form.Control
              type="number"
              value={inputPage > 0 ? inputPage : ''}
              onChange={(e) => setPage(Number(e.target.value))}
            />
            <Button
              variant="secondary"
              onClick={() => props.onPageChange(inputPage)}
              disabled={inputPage > props.max && inputPage < 1}
            >
              GO
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
};
export default AppPagination;
