import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1367 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1366, min: 992 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 991, min: 476 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 475, min: 0 },
    items: 1,
  },
};
const BranchList = [
  {
    name: 'Shimoga',
    address :
    "Shree Guru Shanthaveereshwara Seva Samithi(R), Veerashaiva Vadhu Varara Anveshana Kendra, Infront of Droupadamma Temple, Vijayanagar Layout,Gopala, Shimoga",
    phone: ['08182-254130', '(+91) 9483204130'],
    email: 'sgssssmg@gmail.com',
  },
  {
    name: 'Davangere',
    address:
      "Shree Guru Shanthaveereshwara Seva Samithi(R), Veerashaiva Vadhu Varara Anveshana Kendra, Near old RTO, MCC 'B' block, 14th Cross, Siddaveerappa Layout, Davangere",
    phone: ['08192225783', '(+91) 9448159303'],
    email: 'sgssssmg@gmail.com',
  },
  {
    name: 'Sagar',
    address:
      'Shree Guru Shanthaveereshwara Seva Samithi(R), Veerashaiva Vadhu Varara Anveshana Kendra, 2nd cross, Neharu nagar, BH road, Sagar',
    phone: ['08183-220141', ' (+91) 9449616025'],
    email: 'sgssssmg@gmail.com',
  },
  {
    name: 'Bangalore',
    address:
      'Shree Guru Shanthaveereshwara Seva Samithi(R), Veerashaiva Vadhu Varara Anveshana Kendra, #687, 8th Main, 7th cross, near bapuji nagar railway gate, RPC layout, 2nd stage, Vijayanagar, Bangalore-104',
    phone: ['080-23350130', '(+91) 9480460130'],
    email: 'sgssssmg@gmail.com',
  },
  {
    name: 'Hubli',
    address:
      'Shree Guru Shanthaveereshwara Seva Samithi(R), Veerashaiva Vadhu Varara Anveshana Kendra, Beside Canara Hotel, Hosur Circle, 1st Cross, Hubli',
    phone: ['08362270130', '(+91) 9483460130'],
    email: 'sgssssmg@gmail.com',
  },
  {
    name: 'Mysuru',
    address:
      'Shree Guru Shanthaveereshwara Seva Samithi(R), Veerashaiva Vadhu Varara Anveshana Kendra, F13 & F13/2, Shankar Matt Road, Palace road, Near Japada Katte Matt, Opposite Nataraj Auditorium, Mysuru-570004',
    phone: ['0821 2334130', '(+91) 87628 50130'],
    email: 'sgssssmg@gmail.com',
  },
  {
    name: 'Belagavi',
    address:
      'Shree Guru Shanthaveereshwara Seva Samithi(R), Veerashaiva Vadhu Varara Anveshana Kendra, No. 166. Siddhivinayaka Complex, 2nd Sector, Shivabasava Nagar, Belagavi',
    phone: ['0831 2474130', '(+91) 87622 60130'],
    email: 'sgssssmg@gmail.com',
  },
  {
    name: 'Vijayapura',
    address:
      'Shree Guru Shanthaveereshwara Seva Samithi(R), Veerashaiva Vadhu Varara Anveshana Kendra, Sadashiva Complex, Opp BLDE Engineering College, Ashram Road, Vijayapura - 586 103',
    phone: ['08352 264130', '(+91) 8762488130'],
    email: 'sgssssmg@gmail.com',
  },
  {
    name: 'Tumkur',
    address:
      'Shree Guru Shanthaveereshwara Seva Samithi(R), Veerashaiva Vadhu Varara Anveshana Kendra, SIT Main Road, 12th Cross, Tumkur',
    phone: ['9482997130', '08162255130'],
    email: 'sgssssmg@gmail.com',
  },
  {
    name: 'Chitradurga',
    address:
      'Shree Guru Shanthaveereshwara Seva Samithi(R), Veerashaiva Vadhu Varara Anveshana Kendra, J.C.R. Circle, JCR Layout, Chitradurga',
    phone: ['08194-231130', '8762252130'],
    email: 'sgssssmg@gmail.com',
  }
];

const BranchCarousel = () => {
  return (
    <Carousel responsive={responsive} className="py-4">
      {BranchList.map((i) => (
        <div className="franchise-card mx-2" key={i.name}>
          <h5 className="text-center ">{i.name} branch</h5>
          <div className="franchise-address">
            <div className="d-flex">
              <FontAwesomeIcon icon={faLocationDot} className="me-2" /> <p>{i.address}</p>{' '}
            </div>
            <p>
              <FontAwesomeIcon icon={faPhone} className="me-2" />
              {i.phone.join(', ')}
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} className="me-2" />
              {i.email}
            </p>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default BranchCarousel;
