import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { IProfile } from '../../core/models';
import { ProfileCard } from '../../components';
import { ProfileService } from '../../services';

interface ProfileCarouselProps {
  onOpenProfile: (e: number) => void;
}
const ProfileCarousel = (props: ProfileCarouselProps) => {
  const [profiles, setProfiles] = useState<IProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  const getProfiles = async () => {
    setLoading(true);
    try {
      const res = await ProfileService.getLatestProfiles();
      if (res.status === 200) {
        setProfiles(res.data.data);
      } else {
        alert(res.data.error.message);
      }
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getProfiles();
  }, []);
  return (
    <Container>
      <Row>
        <Col>
          <h2 className="heading-alt text-center">Latest Profiles</h2>
          <h5 className="sub-heading text-center">
            Recently updated profiles for better and faster accessibility
          </h5>
          <Carousel responsive={responsive}>
            {profiles.slice(0, 10)?.map((p: IProfile) => (
              <div className="mx-2" key={p.id}>
                <ProfileCard profile={p} onClick={(e) => props.onOpenProfile(e.id)} />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
      {loading && (
        <div className="my-5 py-4 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" />{' '}
          <span className="fs-5 ms-1">Loading...</span>
        </div>
      )}
    </Container>
  );
};

export default ProfileCarousel;
