import React, { useEffect, useState } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import { ProfileService } from '../../services';
import { Button, Spinner } from 'react-bootstrap';
import { AppPagination } from '../../components';
import { ITestimony } from '../../core/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import Carousel from 'react-multi-carousel';

const TestimonialsPage = () => {
  const [loading, setLoading] = useState(false);
  const [testimonials, setTestimonials] = useState<ITestimony[]>([]);
  const [pagination, setPagination] = useState({
    cur: 1,
    max: 0,
    limit: 20,
  });
  const cardcar = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };
  const getImages = async (page: number = 1) => {
    setLoading(true);
    try {
      const res = await ProfileService.getTestimonials(page, 20);
      if (res.status === 200) {
        if (res.data.data) {
          setTestimonials(res.data.data);
          setPagination({
            cur: res.data.pagination_data.page,
            max: res.data.pagination_data.pages,
            limit: res.data.pagination_data.limit,
          });
        } else {
          setTestimonials([]);
          setPagination({
            cur: 1,
            max: 1,
            limit: 20,
          });
          alert(res.data.message);
        }
      } else {
        alert(res.data.error.message);
      }
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getImages();
  }, []);
  return (
    <>
      <h1 className="heading bg-light mb-0 text-center py-4">Testimonials</h1>
      <div className="container my-5">
        <div className="row">
          {!loading &&
            testimonials.map((i) => (
              <div className="col-lg-3 col-md-2">
                <div className="test-card" key={i.id}>
                  {!i.youtubeLink && (
                    <div
                      className="test-card-img"
                      style={{ backgroundImage: `url(${i.imageUrl})` }}
                    ></div>
                  )}
                  {i.youtubeLink && (
                    <Carousel responsive={cardcar} className="">
                      <div
                        className="test-card-img"
                        style={{ backgroundImage: `url(${i.imageUrl})` }}
                      ></div>
                      <iframe
                        width="100%"
                        height="240px"
                        src={`https://www.youtube.com/embed/${i.youtubeLink.split('/').pop()}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    </Carousel>
                  )}
                  <div className="test-card-body">
                    <h5>
                      {i.brideName} and {i.groomName}
                    </h5>
                    <p>{i.testimony}</p>
                    {i.youtubeLink && (
                      <Button variant="danger" className="mt-2 ">
                        Youtube <FontAwesomeIcon icon={faPlayCircle} />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
        {testimonials.length === 0 && !loading && (
          <h5 className="text-secondary my-5 py-5 text-center">No Data</h5>
        )}
        {loading && (
          <div className="my-5 py-4 d-flex align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" />{' '}
            <span className="fs-5 ms-1">Loading...</span>
          </div>
        )}
        {testimonials.length > 0 && pagination.max > 1 && (
          <div className="d-none d-md-block">
            <AppPagination
              cur={pagination.cur}
              max={pagination.max}
              limit={pagination.limit}
              onPageChange={(n) => getImages(n)}
            />
          </div>
        )}
        <div className="my-5 py-5"></div>
      </div>
    </>
  );
};

export default TestimonialsPage;
