export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
}
export enum FilterType {
  HOME = 'Home',
  GROOM = 'Groom',
  BRIDE = 'Bride',
}
export enum FindType {
  FILE = 'File',
  ID = 'Id',
}
